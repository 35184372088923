import './scss/app.scss'

console.log("it works")
let url_1 = 'https://raw.githubusercontent.com/PoseidonCHNZ/weatherSen/master/EffectJson-final-02.json'

loadDoc(url_1, myFunction1);


function loadDoc(url, cFunction) {
  var xhttp;
  xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      cFunction(this);
    }
  };
  xhttp.open("GET", url, true);
  xhttp.send();
}

function myFunction1(xhttp) {
  var allData = JSON.parse(xhttp.responseText)
  // console.log(allData);

  var WeatherParas = ["ALLWeather(Scaled)", "ALLWeather", "WindSpeed", "Rain", "TemperatureMax", "Radiation"]
  var data1 = [];
  var siteorder = [];
  var y_range = 62;
  var annotaion_X = 8.5;
  for (var i = 0, arrLength = WeatherParas.length; i < arrLength; i++) {

    if (WeatherParas[i] == "ALLWeather(Scaled)") {
      // console.log(WeatherParas[i]);
      function checkWeather(item) {
        return item.weatherPara == WeatherParas[i];
      }
      function compareNumbers(a, b) {
        return -a.weatherE + b.weatherE;
      }
      AllEffect = allData.filter(checkWeather).
        sort(compareNumbers)
      // console.log(AllEffect);

      // for (var i = 0, arrLength = AllEffect.length; i < arrLength; i++) {
      //   siteorder.push((AllEffect[i].Site))
      // }
      siteorder = _.map(AllEffect, 'Site'); //get the Site order by ranking AllWeather(Scaled) values
    } else {
      console.log(WeatherParas[i]);
      function checkWeather(item) {
        return item.weatherPara == WeatherParas[i];
      }

      AllEffect = allData.filter(checkWeather)


      var AllEffect = _.sortBy(AllEffect, function (item) {
        return siteorder.indexOf(item.Site)  //re-order the Site by uisng AllWeather(Scaled) values
      });
    }
    var ii = i + 1;

    console.log(AllEffect);
    var trace = {
      type: 'bar',
      x: _.map(AllEffect, 'Site'),
      y: _.map(AllEffect, 'weatherE')
      , hoverinfo: "none"  // not working
      , hovertemplate: '<br>%{y:.3f}'
      , name: WeatherParas[i]
      , xaxis: 'x' + ii,
      yaxis: 'y' + ii
    };
    data1.push(trace);

  }
  var layout = {
    title: "Weather effect by counter site: Overall and by parameters",
    titlefont: {
      family: 'Arial, sans-serif',
      size: 22,
      color: "#264E86"
    },
    hoverlabel: {
      font: { size: 16 }
    },
    grid: {
      rows: 6,
      columns: 1,
      pattern: 'dependent',// VIP, only one x xais got tick labels
      roworder: 'bottom to top'
    },
    height: 650,
    // width: 900,
    yaxis: {
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, 1],// set y xaix range
      showline: true,
      tick0: 0,
      dtick: 1
    },
    yaxis2: {
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, y_range],
      showline: true,
      tick0: 10,
      dtick: 40
    },
    yaxis3: {               // yaxis3 is the third subplots, actually
      title: 'Weather Sensitivity',
      titlefont: {
        family: 'Arial, sans-serif',
        size: 22,
        color: "#264E86"
      },
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, y_range],
      showline: true,
      tick0: 10,
      dtick: 40
    },
    yaxis4: {
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, y_range],
      showline: true,
      tick0: 10,
      dtick: 40
    },
    yaxis5: {
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, y_range],
      showline: true,
      tick0: 10,
      dtick: 40
    },
    yaxis6: {
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 20,
        color: 'black'
      },
      ticklen: 2,
      tickwidth: 4,
      tickcolor: '#000',
      range: [0, y_range],
      showline: true,
      tick0: 10,
      dtick: 40
    }
    , xaxis: {   //xaxis2-xaxis6 must be turned off maunally
      tickfont: {
        family: 'Old Standard TT, serif',
        size: 16,
        color: 'black'
      },
      ticklen: 5,
      tickwidth: 4,
      tickcolor: '#000',
      tickangle: 45,
      automargin: true //Automatically Adjust Margins of y xaxis labels
      // ,ticks: 'outside' //not working
    }
    , xaxis2: {
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false

    },
    xaxis3: {
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false
    },
    xaxis4: {
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false
    },
    xaxis5: {
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false
    },
    xaxis6: {
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false
    },

    annotations: [
      {
        x: 0.5,// //paper ref range is [0-1]
        y: 0.1,  // as y_range for the first bar group is 1
        // xref: 'x1',  
        // yref: 'y1',
        xref: 'paper',  //paper ref range is [0-1]
        yref: 'paper',
        // xanchor: 'left',
        // yanchor: 'top',
        text: 'ALLWeather(Scaled)',
        // font: {size: 18, color: "#264E86",family:  'Raleway, sans-serif'},
        // showarrow: false,
        showarrow: false,
        font: {
          family: 'Courier New, monospace',
          size: 18,
          color: '#ffffff'
        },
        // align: 'center',
        // arrowhead: 2,
        // arrowsize: 1,
        // arrowwidth: 2,
        // arrowcolor: '#636363',
        // ax: 20,
        // ay: -30,
        // bordercolor: '#c7c7c7',
        // borderwidth: 0.1,
        // borderpad: 1,
        bgcolor: '#7f7f7f',
        // opacity: 0.8
      },
      {
        x: annotaion_X,// not setting this to the far right or left
        y: y_range,
        xref: 'x2',
        yref: 'y2',
        text: 'ALLWeather',
        font: { size: 18, color: "#ff7f0e" },
        showarrow: false

      }
      , {
        x: annotaion_X,
        y: y_range,
        xref: 'x3',
        yref: 'y3',
        text: 'Wind Speed',
        font: { size: 18, color: "#2ca02c" },
        showarrow: false

      }
      , {
        x: annotaion_X,
        y: y_range,
        xref: 'x4',
        yref: 'y4',
        text: 'Rain',
        font: { size: 18, color: "#d62728" },
        showarrow: false

      }
      , {
        x: annotaion_X,
        y: y_range,
        xref: 'x5',
        yref: 'y5',
        text: 'Temperature',
        font: { size: 18, color: "#9467bd" },
        showarrow: false

      }
      , {
        x: annotaion_X,
        y: y_range,
        xref: 'x6',
        yref: 'y6',
        text: 'Radiation',
        font: { size: 18, color: "#8c564b" },
        showarrow: false

      }
    ],
    margin: {
      l: 90,
      r: 20,
      b: 80,
      t: 80,
      pad: 5
    },
    plot_bgcolor: 'rgb(213, 228, 235)',
    paper_bgcolor: 'rgb(213, 228, 235)'
  };

  // var config = {responsive: true};
  Plotly.newPlot('myDiv', data1, layout, 
  { responsive: true }, //this will deactive {displayModeBar: false}
  // config,
  {displayModeBar: false}
  );

}
